import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoLogoInstagram, IoLogoFacebook, IoLogoWhatsapp } from "react-icons/io5";
import logo from './assets/logo-white.png';
import PrivacyButton from './PrivacyButton';
import TermsButton from './TermsButton';

function Footer() {

  return (
    <Container fluid className='py-5 bg-success'>
      <Row>
        <Col xs={12} className='py-4 text-center'>
          <img src={logo} alt='Clean & Kill Logo' className='img-fluid logo-footer' />

          <p className='text-center my-5'>
            <a href='https://www.facebook.com/CleanandKill/' target='_blank' rel='noreferrer' className='text-decoration-none text-white fs-5'>
              <IoLogoFacebook size={40} />
            </a>
            <a href='https://www.instagram.com/cleanandkill/' target='_blank' rel='noreferrer' className='text-decoration-none text-white mx-4 fs-5'>
              <IoLogoInstagram size={40} />
            </a>
            <a href='https://wa.me/5213315635078' target='_blank' rel='noreferrer' className='text-decoration-none text-white fs-5'>
              <IoLogoWhatsapp size={40} />
            </a>
          </p>

          <p className='my-3 text-white text-center fw-lighter'>
            <small>Regístrate y obtén grandes promociones.</small> <br />
            <small>{(new Date().getFullYear())} (CLEANANDKILL) &copy; Todos los Derechos Reservados</small>
          </p>
        </Col>
        <Col xs={6} className='text-center'>
          <PrivacyButton />
        </Col>
        <Col xs={6} className='text-center'>
          <TermsButton />
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;