import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import bg from './assets/comprar-ahora/bg.png';

function ComprarAhora() {


  return (
    <Container id='comprar-ahora' className='mb-4 py-lg-5 comprar-ahora' style={{ backgroundImage: `url(${bg})` }}>
      <Row className='align-items-center justify-content-center py-5 my-lg-5'>
        <Col xs={12} md={8} className='text-center my-5 py-5 px-4 px-md-0'>
          <h2 className='text-center text-white fs-1 mb-3 fw-semibold'>Libérate de los insectos y disfruta tu espacio al máximo.</h2>

          <p className='text-center fs-4 mb-5 fw-light'>
            Protégete de enfermedades traídas por mosquitos y microorganismos. Solo aplica en las superficies y rincones de tu casa o negocio y deja que actúe por sí solo.
          </p>

          <Button rel="a" className='rounded-5 shadow bg-white fw-semibold' target='_blank' href='https://cleanandkill.myshopify.com/collections/all' variant='light' size='lg'>Comprar ahora</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ComprarAhora;