import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Slider1 from './assets/inicio/banners/1.png';
import Slider2 from './assets/inicio/banners/2.png';

function Inicio() {

  const slides = [
    {
      alt: 'Slider 1',
      src: Slider1,
    },
    {
      alt: 'Slider 2',
      src: Slider2,
    },
  ];

  return (
    <Container className='mb-4'>
      <Carousel id="carousel-inicio" fade controls={false} indicators={true} touch={true} interval={3000}>
        {
          slides.map((slide, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={slide.src}
                alt={slide.alt}
              />
            </Carousel.Item>
          ))
        }
      </Carousel>
    </Container>
  );
}

export default Inicio;