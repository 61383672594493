import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-multi-carousel';
import ambiente1 from './assets/ambientes/1.png';
import ambiente2 from './assets/ambientes/2.png';
import ambiente3 from './assets/ambientes/3.png';
import ambiente4 from './assets/ambientes/4.png';
import ambiente5 from './assets/ambientes/5.png';
import ambiente6 from './assets/ambientes/6.png';
import ambiente7 from './assets/ambientes/7.png';
import ambiente8 from './assets/ambientes/8.png';
import ambiente9 from './assets/ambientes/9.png';
import ambiente10 from './assets/ambientes/10.png';

function Ambientes() {

  const shopUrl = 'https://cleanandkill.myshopify.com/'
  const ambientes = [
    [
      {
        alt: 'Biofertilizante Florescendo',
        description: 'Nutrición orgánica, belleza natural. ¡Florescendo es la clave!',
        src: ambiente1,
        col: 4,
        products: [
          {
            name: '500ML',
            slug: 'florescendo-500-ml'
          },
          {
            name: '1L',
            slug: 'florescendo-1l'
          },
          {
            name: '5L',
            slug: 'florescendo-5l'
          },
        ]
      },
      {
        alt: 'Jabón desinfectante baños y superficies Huele rico',
        description: 'Limpia, desinfecta y disfruta de un aroma que dura. ¡Huele rico, vive mejor!',
        src: ambiente2,
        col: 8,
        products: [
          {
            name: '500ML',
            slug: 'banos-y-superficies-500ml'
          },
          {
            name: '1L',
            slug: 'banos-y-superficies-1l'
          },
          {
            name: '5L',
            slug: 'banos-y-superficies-5l'
          },
        ]
      },
    ],
    [
      {
        alt: 'Mata ácaros infalible',
        description: 'Respira limpio, duerme tranquilo. ¡Adiós ácaros con Clean & Kill!',
        src: ambiente3,
        col: 4,
        products: [
          {
            name: '500ML',
            slug: 'mata-acaros-infalible-500-ml'
          },
          {
            name: '1L',
            slug: 'mata-acaros-infalible-1l'
          },
          {
            name: '5L',
            slug: 'mata-acaros-infalible-5l'
          },
        ]
      },
      {
        alt: 'Lavamanos Huele Rico',
        description: 'Higiene y frescura en cada lavado. ¡Huele rico, se siente mejor!',
        src: ambiente4,
        col: 8,
        products: [
          {
            name: '500ML',
            slug: 'lava-manos-500-ml'
          },
          {
            name: '1L',
            slug: 'lava-manos-1l'
          },
          {
            name: '5L',
            slug: 'lava-manos-5l'
          },
        ]
      },
    ],
    [
      {
        alt: 'Desengrasante infalible',
        description: 'Limpieza sin esfuerzo, resultados impecables. ¡Clean & Kill desengrasa por ti!',
        src: ambiente5,
        col: 4,
        products: [
          {
            name: '500ML',
            slug: 'desengrasante-500-ml'
          },
          {
            name: '1L',
            slug: 'desengrasante-1l'
          },
          {
            name: '5L',
            slug: 'desengrasante-5l'
          },
        ]
      },
      {
        alt: 'Mata Ácaros Infalible',
        description: 'Respira limpio, duerme tranquilo. ¡Adiós ácaros con Clean & Kill!',
        src: ambiente6,
        col: 8,
        products: [
          {
            name: '500ML',
            slug: 'mata-acaros-infalible-500-ml'
          },
          {
            name: '1L',
            slug: 'mata-acaros-infalible-1l'
          },
          {
            name: '5L',
            slug: 'mata-acaros-infalible-5l'
          },
        ]
      },
    ],
    [
      {
        alt: 'Mata moscas infalible',
        description: 'Sin moscas, sin preocupaciones: protección hasta por 30 días.',
        src: ambiente7,
        col: 4,
        products: [
          {
            name: '240ML',
            slug: 'mata-moscas-infalible-240ml'
          },
          {
            name: '480ML',
            slug: 'mata-moscas-infalible-480ml'
          },
          {
            name: '1L',
            slug: 'mata-moscas-infalible-1l'
          },
          {
            name: '5L',
            slug: 'mata-moscas-infalible-5l'
          },
        ]
      },
      {
        alt: 'Mata Moscos Infalible',
        description: 'Cuidamos tu hogar y tu salud: sin zancudos, sin preocupaciones.',
        src: ambiente8,
        col: 8,
        products: [
          {
            name: '480ML',
            slug: 'mata-moscos-infalible-480ml'
          },
          {
            name: '1L',
            slug: 'mata-moscos-infalible-1l'
          },
          {
            name: '5L',
            slug: 'mata-moscos-infalible-5l'
          },
        ]
      },
    ],
    [
      {
        alt: 'Mata virus infalible',
        description: 'Desinfección profunda y segura: 99.9% de protección en cada spray.',
        src: ambiente9,
        col: 4,
        products: [
          {
            name: '240ML',
            slug: 'mata-virus-infalible-240ml'
          },
          {
            name: '480ML',
            slug: 'mata-virus-infalible-480ml'
          },
          {
            name: '1L',
            slug: 'mata-virus-infalible-1l'
          },
          {
            name: '5L',
            slug: 'mata-virus-infalible-5l'
          },
        ]
      },
      {
        alt: 'Mata cucarachas Infalible',
        description: 'Elimina plagas y cuida a los tuyos. Fácil, seguro, ¡infalible!',
        src: ambiente10,
        col: 8,
        products: [
          {
            name: '480ML',
            slug: 'mata-cucarachas-inf-480ml'
          },
          {
            name: '1L',
            slug: 'mata-cucarachas-inf-1l'
          },
          {
            name: '5L',
            slug: 'mata-cucarachas-inf-5l'
          },
        ]
      },
    ]
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };

  return (
    <Container className='mb-4'>
      <Row>
        <Col>
          <Carousel
            dotListClass='ambientes-dots'
            showDots={true}
            arrows={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5s"
            transitionDuration={500}
          >
            {ambientes.map((ambienteGroup, i) => (
              <Row xs={1} md={2}>
                {
                  ambienteGroup.map((ambiente, j) => (
                    <Col key={i + j + 1} xs={12} md={ambiente.col} className='position-relative ambiente-container'>
                      <div style={{ background: `url(${ambiente.src})` }} className="ambiente-skeleton"></div>
                      <div className='ambiente-description d-flex align-items-center justify-content-center flex-column p-5'>
                        <h4 className='mb-2 text-uppercase text-center text-white'>{ambiente.alt}</h4>
                        <p className='text-white text-center d-none d-lg-inline'>{ambiente.description}</p>
                        <div className='mt-2 text-center'>
                          {
                            ambiente?.products.length > 0 && ambiente?.products.map(product =>
                              <Button
                                className='me-2 mt-1'
                                key={product.slug}
                                rel='a'
                                target='_blank'
                                variant='outline-primary'
                                href={`${shopUrl}products/${product.slug}`}
                              >
                                {product.name}
                              </Button>
                            )
                          }
                        </div>
                      </div>
                      <img
                        loading='lazy'
                        src={ambiente.src}
                        className="z-1 position-relative w-100 ambiente-img"
                        alt={ambiente.alt} />
                    </Col>
                  ))
                }
              </Row>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container >
  );
}

export default Ambientes;