import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-multi-carousel';
import producto1 from './assets/productos/1.png';
import producto2 from './assets/productos/2.png';
import producto3 from './assets/productos/3.png';
import producto4 from './assets/productos/4.png';
import producto5 from './assets/productos/5.png';
import producto6 from './assets/productos/6.png';
import producto7 from './assets/productos/7.png';
import producto8 from './assets/productos/8.png';
import producto9 from './assets/productos/9.png';

function Productos() {

  const shopUrl = 'https://cleanandkill.myshopify.com/'

  const products = [
    {
      img: producto1,
      alt: 'Lavamanos Huele Rico',
      description: 'Higiene y frescura en cada lavado. ¡Huele rico, se siente mejor!',
      products: [
        {
          name: '500ML',
          slug: 'lava-manos-500-ml'
        },
        {
          name: '1L',
          slug: 'lava-manos-1l'
        },
        {
          name: '5L',
          slug: 'lava-manos-5l'
        },
      ],
      order: 1,
    },
    {
      img: producto2,
      alt: 'Biofertilizante Florescendo',
      description: 'Nutrición orgánica, belleza natural. ¡Florescendo es la clave!',
      products: [
        {
          name: '500ML',
          slug: 'florescendo-500-ml'
        },
        {
          name: '1L',
          slug: 'florescendo-1l'
        },
        {
          name: '5L',
          slug: 'florescendo-5l'
        },
      ],
      order: 3,
    },
    {
      img: producto3,
      alt: 'Biofertilizante Florescendo',
      description: 'Nutrición orgánica, belleza natural. ¡Florescendo es la clave!',
      products: [
        {
          name: '500ML',
          slug: 'florescendo-500-ml'
        },
        {
          name: '1L',
          slug: 'florescendo-1l'
        },
        {
          name: '5L',
          slug: 'florescendo-5l'
        },
      ],
      order: 5,
    },
    {
      img: producto4,
      alt: 'Desengrasante infalible',
      description: 'Limpieza sin esfuerzo, resultados impecables. ¡Clean & Kill desengrasa por ti!',
      products: [
        {
          name: '500ML',
          slug: 'desengrasante-500-ml'
        },
        {
          name: '1L',
          slug: 'desengrasante-1l'
        },
        {
          name: '5L',
          slug: 'desengrasante-5l'
        },
      ],
      order: 7,
    },
    {
      img: producto5,
      alt: 'Mata Ácaros Infalible',
      description: 'Respira limpio, duerme tranquilo. ¡Adiós ácaros con Clean & Kill!',
      products: [
        {
          name: '500ML',
          slug: 'mata-acaros-infalible-500-ml'
        },
        {
          name: '1L',
          slug: 'mata-acaros-infalible-1l'
        },
        {
          name: '5L',
          slug: 'mata-acaros-infalible-5l'
        },
      ],
      order: 9,
    },
    {
      img: producto6,
      alt: 'Mata virus infalible',
      description: 'Desinfección profunda y segura: 99.9% de protección en cada spray.',
      products: [
        {
          name: '240ML',
          slug: 'mata-virus-infalible-240ml'
        },
        {
          name: '480ML',
          slug: 'mata-virus-infalible-480ml'
        },
        {
          name: '1L',
          slug: 'mata-virus-infalible-1l'
        },
        {
          name: '5L',
          slug: 'mata-virus-infalible-5l'
        },
      ],
      order: 2,
    },
    {
      img: producto7,
      alt: 'Mata Moscos Infalible',
      description: 'Cuidamos tu hogar y tu salud: sin zancudos, sin preocupaciones.',
      products: [
        {
          name: '480ML',
          slug: 'mata-moscos-infalible-480ml'
        },
        {
          name: '1L',
          slug: 'mata-moscos-infalible-1l'
        },
        {
          name: '5L',
          slug: 'mata-moscos-infalible-5l'
        },
      ],
      order: 4,
    },
    {
      img: producto8,
      alt: 'Mata moscas infalible',
      description: 'Sin moscas, sin preocupaciones: protección hasta por 30 días.',
      products: [
        {
          name: '240ML',
          slug: 'mata-moscas-infalible-240ml'
        },
        {
          name: '480ML',
          slug: 'mata-moscas-infalible-480ml'
        },
        {
          name: '1L',
          slug: 'mata-moscas-infalible-1l'
        },
        {
          name: '5L',
          slug: 'mata-moscas-infalible-5l'
        },
      ],
      order: 6,
    },
    {
      img: producto9,
      alt: 'Mata cucarachas Infalible',
      description: 'Elimina plagas y cuida a los tuyos. Fácil, seguro, ¡infalible!',
      products: [
        {
          name: '480ML',
          slug: 'mata-cucarachas-inf-480ml'
        },
        {
          name: '1L',
          slug: 'mata-cucarachas-inf-1l'
        },
        {
          name: '5L',
          slug: 'mata-cucarachas-inf-5l'
        },
      ],
      order: 8,
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    }
  };


  return (
    <Container id='productos' className='mb-4'>
      <Row className='align-items-center justify-content-center py-5'>
        <Col xs={12}>
          <h2 className='text-center fs-2 text-uppercase'>Nuestros Productos</h2>

          <Row className='align-items-center justify-content-center mt-2 mb-5'>
            <Col xs={5} sm={4} md={3} lg={2} xl={1} className='border border-black'></Col>
          </Row>

          <Carousel
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5s"
            transitionDuration={500}
          >
            {products.sort(
              (a, b) => a.order - b.order
            ).map((product, index) => (
              <div key={index} className='mx-2 d-inline-block'>
                <div className='product-description d-flex align-items-center justify-content-center flex-column p-5'>
                  <h4 className='mb-2 text-uppercase text-center text-white'>{product.alt}</h4>
                  <p className='text-white text-center d-none d-lg-inline'>{product.description}</p>
                  <div className='mt-2 text-center'>
                    {
                      product?.products.length > 0 && product?.products.map(variant =>
                        <Button
                          className='me-2 mt-1'
                          key={variant.slug}
                          rel='a'
                          target='_blank'
                          variant='outline-primary'
                          href={`${shopUrl}products/${variant.slug}`}
                        >
                          {variant.name}
                        </Button>
                      )
                    }
                  </div>
                </div>
                <img loading='lazy' src={product.img} className='product-img img-fluid' alt={product.alt} />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default Productos;