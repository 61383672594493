import Header from './Header';
import Inicio from './Inicio';
import Ambientes from './Ambientes';
import ComprarAhora from './ComprarAhora';
import Productos from './Productos';
import Beneficios from './Beneficios';
import Footer from './Footer';

function App() {
  return (
    <div className="App" id='inicio'>
      <Header />
      <Inicio />
      <Ambientes />
      <ComprarAhora />
      <Productos />
      <Beneficios />
      <Footer />
    </div>
  );
}

export default App;
