
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function TermsButton() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return <>
    <Button variant="link" className='text-decoration-none text-white fs-5' onClick={handleShow}>
      Términos y condiciones
    </Button>

    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title>Términos y condiciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><b>I. T&#233;rminos y condiciones aplicables al uso del sitio web.</b> </p>
        <p>1. Gracias por visitar la secci&#243;n de t&#233;rminos y condiciones (en lo posterior los &#8220;T&#233;rminos y Condiciones&#8221;) de Clean &amp; Kill (en lo posterior &#8220;CLEAN &amp; KILL&#8221;). El uso del sitio web www.cleanandkill.mx (en lo posterior el &#8220;Sitio Web&#8221;) est&#225; sujeto a los siguientes T&#233;rminos y Condiciones y leyes aplicables. </p>
        <p> 2. Al acceder y navegar en el Sitio Web, usted acepta estos &#8220;T&#233;rminos y Condiciones&#8221; sin limitaci&#243;n o condici&#243;n alguna, incluyendo los t&#233;rminos y condiciones adicionales o modificados que &#8220;CLEAN &amp; KILL&#8221; publique posteriormente. Ciertos servicios y otras ofertas disponibles en el &#8220;Sitio Web&#8221; pueden tener t&#233;rminos y condiciones adicionales aplicables, los cuales pueden leerse en las p&#225;ginas que contengan dichos servicios u ofertas, o por medio de un v&#237;nculo en dichas p&#225;ginas u otras direcciones referenciadas hacia dichos t&#233;rminos y condiciones adicionales. </p>
        <p> 3. El contrato de compraventa entre &#8220;CLEAN &amp; KILL&#8221; y el consumidor se perfeccionar&#225; una vez que el precio de la mercanc&#237;a haya sido cubierto y procesado por los sistemas de pago y reconocido por &#8220;CLEAN &amp; KILL&#8221; en un plazo no mayor a 3 (tres) d&#237;as h&#225;biles contados a partir de la fecha en que el consumidor efect&#250;e el pago a trav&#233;s de los medios dispuestos para tal fin y considerados dentro de los presentes &#8220;T&#233;rminos y Condiciones&#8221;; una vez reconocido el pago, &#8220;CLEAN &amp; KILL&#8221; enviar&#225; un correo electr&#243;nico al consumidor a la direcci&#243;n que &#233;ste haya indicado al efectuar la selecci&#243;n de los art&#237;culos, informando que su transacci&#243;n ha sido completada. </p>
        <p>  </p>
        <p><b>II. Propiedad Intelectual</b> </p>
        <p>  </p>
        <p>1. El contenido del &#8220;Sitio Web&#8221; est&#225; protegido por las normas del Derecho Intelectual e Industrial, por lo que no podr&#225; ser reproducido, transformado y/o comunicado p&#250;blicamente sin la autorizaci&#243;n previa y por escrito de &#8220;CLEAN &amp; KILL&#8221;. Con el uso del &#8220;Sitio Web&#8221; no se otorga derecho alguno respecto de la propiedad intelectual de &#8220;CLEAN &amp; KILL&#8221; o de tercero alguno, por lo que no se autoriza acto alguno que pudiese afectar, interferir, infringir, violar, ser en detrimento de y/o poner en desventaja el prestigio, la imagen y/o el buen nombre de &#8220;CLEAN &amp; KILL&#8221; y/o los derechos industriales o autorales de &#8220;CLEAN &amp; KILL&#8221;. </p>
        <p>  </p>
        <p><b> </b> </p>


        <p><b>III. Limitaciones al comportamiento de usuarios</b> </p>
        <p>  </p>
        <p>1. Usted acepta abstenerse de: </p>
        <p>(i) utilizar el Sitio Web para cualquier fin il&#237;cito; </p>
        <p>(ii) publicar p&#225;ginas web que contengan v&#237;nculos que inicien descargas de material en franca infracci&#243;n de propiedad intelectual o que sean il&#237;citos; </p>
        <p>(iii) acosar u hostigar a otra persona; </p>
        <p>(iv) participar en cualquier actividad que viole los derechos de privacidad de otras personas; </p>
        <p>(v) realizar rifas, sorteos o concursos por Internet que violen las leyes aplicables; </p>
        <p>(vi) publicar, distribuir o transmitir cualquier gusano, virus, archivo u otro c&#243;digo, archivo o programa perjudicial por medio del &#8220;Sitio Web&#8221;; </p>
        <p>(vii) afectar de forma negativa la capacidad de otros usuarios de usar el &#8220;Sitio Web&#8221;; </p>
        <p>(viii) interferir, afectar o perjudicar en cualquier modo el &#8220;Sitio Web&#8221; o los servidores o redes conectados al mismo; </p>
        <p>(ix) utilizar el &#8220;Sitio Web&#8221; para acceder a las cuentas de otras personas sin su autorizaci&#243;n; </p>
        <p>(x) intentar vulnerar las medidas de seguridad de &#8220;CLEAN &amp; KILL&#8221;, u obtener o eludir las contrase&#241;as de otras personas; y/o </p>
        <p>(xi) participar en ataques de denegaci&#243;n de servicio, esto es, en acciones dise&#241;adas para afectar el acceso a la red bombardeando al &#8220;Sitio Web&#8221; con tr&#225;fico in&#250;til. </p>
        <p><b> </b> </p>
        <p><b>IV. Sobre el contenido</b> </p>
        <p>  </p>
        <p>1. La informaci&#243;n del &#8220;Sitio Web&#8221; incluye la descripci&#243;n de productos que pueden estar disponibles &#250;nicamente en M&#233;xico&#8221;. Este sitio es operado en M&#233;xico y &#8220;CLEAN &amp; KILL&#8221; no realiza declaraci&#243;n alguna respecto a que el contenido que se provee es aplicable o apropiado para el uso en otros lugares y exportaciones. </p>

        <p><b>Limitaci&#243;n de responsabilidad </b></p>
        <p>&#8220;CLEAN &amp; KILL&#8221; no asumir&#225; ning&#250;n tipo de responsabilidad por los da&#241;os y perjuicios causados por el mal uso que usted haga con el &#8220;Sitio Web&#8221; y/o alguno de sus productos. </p>
        <p>De manera enunciativa mas no limitativa, se incluye entre da&#241;os y perjuicios por el mal uso o el uso incorrecto del &#8220;Sitio Web&#8221; los causados por: </p>
        <p>-Uso incorrecto del sitio web, incapacidad o imposibilidad de usarlo. </p>
        <p>-Falla en el desempe&#241;o, error, omisi&#243;n, interrupci&#243;n y/o defecto. </p>
        <p>-Virus. </p>
        <p>-Falla en l&#237;nea. </p>
        <p>&#8220;CLEAN &amp; KILL&#8221; no se har&#225; responsable de ninguna manera, por los da&#241;os y perjuicios provocados por el uso incorrecto de alguno de nuestros productos, ya que en cada envase se describe la forma de aplicaci&#243;n y nunca se deber&#225; utilizar de una manera distinta a la especificada. </p>
        <p>De manera enunciativa mas limitativa el uso incorrecto de los productos incluye: </p>
        <p>-Uso excesivo del producto </p>
        <p>-Aplicaci&#243;n incorrecta </p>
        <p>-Uso para un fin distinto al que indica el envase. Es importante reiterar que los productos de &#8220;CLEAN &amp; KILL&#8221; no son repelentes, por lo que su mal uso puede considerarse nocivo para la salud. </p>
        <p>-Uso contrario o diferente ignorando las precauciones y/o instrucciones </p>
        <p>Asimismo, no asumiremos ning&#250;n tipo de responsabilidad, incluso cuando hayamos sido negligentes o hubi&#233;semos sido informados de la posibilidad de tales da&#241;os y perjuicios, o ambas cosas. </p>
        <p><b> </b></p>
        <p><b>V. Edad de los usuarios</b> </p>
        <p>  </p>
        <p>1. El &#8220;Sitio Web&#8221; est&#225; dise&#241;ado para ser usados por individuos que tengan al menos 18 a&#241;os de edad cumplidos. Los usuarios menores a 18 a&#241;os de edad deben obtener la ayuda de su padre o tutor para utilizarlo.  </p>
        <p><b> </b> </p>
        <p><b>VI. Condiciones de compra</b> </p>
        <p>  </p>
        <p>1. &#218;nicamente podr&#225; comprar productos: </p>
        <p>(i) que est&#233;n disponibles para su venta y cuyos detalles aparezcan en la tienda online del &#8220;Sitio Web&#8221;; </p>
        <p>(ii) con entrega en M&#233;xico a los c&#243;digos postales autorizados por el proveedor log&#237;stico. </p>
        <p>(iii) sin fin de posterior comercializaci&#243;n o lucro por parte del adquirente. </p>
        <p>2. Los detalles de los productos disponibles para su compra (incluyendo su correspondiente precio) se encuentran especificados en la tienda online del &#8220;Sitio Web&#8221;. Esta &#250;ltima adoptar&#225; todas las medidas necesarias y razonables para garantizar que todos los detalles, las descripciones y los precios de los productos que aparecen en el Sitio Web sean correctos cuando se incluya la informaci&#243;n  </p>
        <p>3. Los productos enunciados en el &#8220;Sitio Web&#8221; sirven para fines de informaci&#243;n general sobre productos comercializados por &#8220;CLEAN &amp; KILL&#8221; y se ofrecen en la p&#225;gina en las cantidades disponibles al momento en que se perfeccione el contrato de compraventa entre &#8220;CLEAN &amp; KILL&#8221; y el comprador y con las caracter&#237;sticas del producto. </p>
        <p>4.  Debido a la simultaneidad de las operaciones vinculadas a este medio, en caso de que uno o varios de los productos contenidos en su pedido no se encontrar&#225;n disponibles, usted recibir&#225; una notificaci&#243;n por correo electr&#243;nico inform&#225;ndole de cu&#225;l o cu&#225;les son los productos que no est&#225;n disponibles. En tal caso, usted dispondr&#225; de 2 (dos) d&#237;as h&#225;biles, a partir de la fecha en que haya recibido esta notificaci&#243;n, para comunicarse al &#225;rea de servicio al cliente, v&#237;a telef&#243;nica al 331 563 5078, o al correo electr&#243;nico matamoscasinfalible@gmail.com, a efecto de informar a &#8220;CLEAN &amp; KILL&#8221; de su elecci&#243;n entre las dos siguientes opciones: </p>
        <p> (i) usted podr&#225; elegir otro producto cuyo valor sea igual o menor al valor de venta del producto que usted haya comprado y respecto del cual le haya sido notificado como no disponible. </p>
        <p>(ii) solicitar el reembolso por el importe total del valor del producto que usted haya comprado y respecto del cual le haya sido notificado como no disponible. En este segundo caso, el reembolso se llevar&#225; a cabo en un m&#225;ximo de 30 (treinta) d&#237;as h&#225;biles. </p>
        <p>  </p>
        <p><b>VII. Sobre el pago</b> </p>
        <p>  </p>
        <p>1.  Podr&#225; pagar con su tarjeta bancaria (tarjeta de cr&#233;dito o d&#233;bito), con el sistema de pago conocido como <i>Paypal. </i>El cobro en su tarjeta bancaria se realizar&#225; cuando se formalice la compraventa en t&#233;rminos de la legislaci&#243;n aplicable. </p>
        <p>2.  Al momento de elegir pago con tarjeta de cr&#233;dito o d&#233;bito, esta plataforma emitir&#225; un formato de pago que se le enviar&#225; a su correo electr&#243;nico registrado, con la finalidad de que el cliente reconozca su compra y el cargo a esa tarjeta, y autorizar el env&#237;o. </p>
        <p>3.  Una vez que su pago sea reflejado, inmediatamente se le notificara v&#237;a correo electr&#243;nico y se iniciara el proceso para su envi&#243; en tiempo y forma. </p>
        <p><b> </b> </p>
        <p><b>VIII. Entregas</b> </p>
        <p>  </p>
        <p>1. &#8220;CLEAN &amp; KILL&#8221; realizar&#225; la entrega de su pedido en la direcci&#243;n, dentro del territorio mexicano, que usted haya se&#241;alado e ingresado en el &#8220;Sitio Web&#8221;. Las entregas se realizar&#225;n &#250;nicamente a trav&#233;s de proveedores log&#237;sticos, por lo que los horarios y d&#237;as de entrega estar&#225;n determinados por estos &#250;ltimos. </p>
        <p>2. Las entregas pueden efectuarse por partes, dentro del plazo se&#241;alado al momento de la Confirmaci&#243;n del Pedido, dicho plazo empezar&#225; a correr una vez que sea aprobado el pago; el usuario podr&#225; revisar el status de entrega de su producto en el portal. </p>
        <p>3. En caso que el Producto no sea entregado en el plazo fijado, no se le podr&#225; reclamar ning&#250;n tipo de inter&#233;s a la Empresa, el Usuario podr&#225; contactar al Centro de Atenci&#243;n a Clientes de la Empresa, donde se expedir&#225; un documento que se&#241;ale el Estado de Env&#237;o y en el cual se especifique las razones del retraso. </p>
        <p>4. En &#8220;CLEAN &amp; KILL&#8221; todos los productos cuentan con un seguro de envi&#243; y de entrega que cubre el 80% del valor del producto (en caso de un robo o extrav&#237;o el producto por parte de nuestro proveedor log&#237;stico). </p>
        <p><b> </b></p>
        <p><b>IX. Obst&#225;culos al suministro </b> </p>
        <p>  </p>
        <p>1. Si &#8220;CLEAN &amp; KILL&#8221; detecta, despu&#233;s de celebrar el contrato, que no puede suministrar los o parte de los productos pedidos en el &#8220;Sitio Web&#8221; y dicha imposibilidad de suministro no es imputable a usted, usted recibir&#225; un correo electr&#243;nico a la direcci&#243;n que usted nos haya proporcionado para hacer de su conocimiento de esto, en cuyo caso &#8220;CLEAN &amp; KILL&#8221; dispondr&#225; de 5 (cinco) d&#237;as h&#225;biles adicionales a partir de la fecha en que usted haya recibido dicho aviso, para que este en posibilidad de satisfacer el suministro de los o el producto faltante; en caso de que no haya sido posible realizar el suministro del mismo, Usted recibir&#225; un segundo correo electr&#243;nico donde se le informar&#225; respecto de esta situaci&#243;n en cuyo caso Usted tendr&#225; derecho a terminar el contrato.  </p>
        <p> </p>
        <p><b>X. Cambios o Devoluciones de productos</b> </p>
        <p>  </p>
        <p>1. En caso de que su pedido haya sido despachado: </p>
        <p>(i) por un art&#237;culo incorrecto. </p>
        <p>(ii) por no corresponder a la marca o especificaciones y dem&#225;s elementos sustanciales bajo los cuales se haya ofrecido, exceptuando reclamos por calidad. </p>
        <p>Usted podr&#225; realizar la devoluci&#243;n del producto siempre y cuando se cumpla con las siguientes condiciones y requisitos: </p>
        <p>(i) el producto permanezca nuevo y sin uso y no haya sufrido un deterioro esencial, irreparable y grave por causas imputables a usted. </p>
        <p>(ii) el producto se devuelva con empaque, caja y etiquetas originales. </p>
        <p>(iii) se encuentre dentro del plazo de 15 (quince) d&#237;as calendario contados a partir de la fecha en que usted haya recibido el producto. </p>
        <p>(iv) usted entregue constancia de que el producto fue comprado en el &#8220;Sitio Web&#8221;. </p>
        <p>2. En caso de recibir productos da&#241;ados es necesario reportarlo durante los 3 (tres) d&#237;as posteriores a la fecha de recibo del pedido, usted podr&#225; realizar el cambio del producto o un reembolso &#237;ntegro del precio que haya sido pagado, siempre y cuando se cumpla con las siguientes condiciones y requisitos: </p>
        <p>(i) el producto debe de estar completo, y no hubiese sido alterado por usted. </p>
        <p>(ii) que el producto se presente dentro de su empaque original, ya sea caja, envoltorio, o cualquier otro dentro del cual le haya sido entregado, as&#237; como tambi&#233;n deber&#225;n traer adheridas en su forma original las etiquetas originales, sin desprender las mismas. </p>
        <p>(iii) se encuentre dentro del plazo de 3 (tres) d&#237;as calendario contados a partir de la fecha en que usted haya recibido el producto, y </p>
        <p>(iv) usted entregue constancia de que el producto fue comprado en el &#8220;Sitio Web&#8221;.  </p>
        <p>3. La garant&#237;a del producto son 3 (tres) d&#237;as posteriores a la fecha de recibo del pedido, usted podr&#225; realizar la devoluci&#243;n o el cambio del producto, &#250;nicamente cuando presente una falla de calidad y cumpla con las siguientes condiciones y requisitos: </p>
        <p>(i) presente su n&#250;mero de pedido y fecha de compra. </p>
        <p>(ii) cuando &#233;ste presenta un da&#241;o o deterioro prematuro, debidamente verificado y siempre que el producto haya tenido el uso apropiado para el que ha sido dise&#241;ado </p>
        <p>(iii) se realizar&#225; una evaluaci&#243;n del producto por parte de nuestro personal calificado y/o t&#233;cnicos de la marca para la aprobaci&#243;n. </p>
        <p>(iiii) cuando exista alg&#250;n defecto de fabricaci&#243;n y que el da&#241;o presentado no se debe a una mala utilizaci&#243;n del mismo. </p>
        <p>La garant&#237;a no cubre da&#241;os causados por desgaste natural, accidentes o mala utilizaci&#243;n del producto.  </p>
        <p>4. Una vez autorizada y verificada la informaci&#243;n, &#8220;CLEAN &amp; KILL&#8221; ser&#225; el responsable solventando el 100% los gastos log&#237;sticos. Exclusivamente dentro del plazo se&#241;alado: </p>
        <p>(i) cambio. </p>
        <p>(i) devoluci&#243;n (garant&#237;a). Usted ingresar&#225; a su cuenta en &#8220;CLEAN &amp; KILL&#8221; y podr&#225; solicitar una gu&#237;a de retorno a nuestras instalaciones, con una vigencia de 5 d&#237;as h&#225;biles. Depositando el paquete en la sucursal de la paqueter&#237;a correspondiente. </p>
        <p>5. Cumpliendo los 3 (tres) de haber recibido su pedido, si usted requiere: </p>
        <p>(i) cambio por modelo </p>
        <p>(ii) cambio por otro art&#237;culo, el cliente ser&#225; el responsable solventando el 100% de los gastos log&#237;sticos. </p>
        <p>6. Todos los cambios est&#225;n sujetos a disponibilidad del producto, en caso de que este se encuentre agotado; el cambio deber&#225; hacerse por alg&#250;n otro producto o si el cliente as&#237; lo decide o se proceder&#225; al reembolso. </p>
        <p>7. En productos de oferta no hay devoluci&#243;n solo cambios que est&#225;n sujetos a disponibilidad de producto y el cliente ser&#225; el responsable solventando el 100% de los gastos log&#237;sticos. </p>
        <p>8. Para recibir informaci&#243;n por parte de &#8220;CLEAN &amp; KILL&#8221; sobre el proceso de devoluci&#243;n, favor de enviar un correo electr&#243;nico a <b>matamoscasinfalible@gmail.com</b> o al tel&#233;fono <b>(33) 15 63 50 78</b>, &#8220;CLEAN &amp; KILL&#8221; por ese mismo le informar&#225; sobre la procedencia o no de la devoluci&#243;n. Una vez que se le informe sobre la procedencia de la devoluci&#243;n, si su pago fue realizado a trav&#233;s de su tarjeta de cr&#233;dito o d&#233;bito su reembolso ser&#225; efectuado en un periodo de 30 d&#237;as h&#225;biles, en caso de que dicho pago haya sido realizado a trav&#233;s de pagos por medio de <i>Paypal</i>, todo lo relacionado al reembolso se sujetar&#225; a los t&#233;rminos dispuestos por dicho medio de pago y en caso de haberse realizado el pago a trav&#233;s de dep&#243;sito, &#8220;CLEAN &amp; KILL&#8221; se contactar&#225; con usted a trav&#233;s de correo electr&#243;nico a efecto de informarle sobre la forma de reintegraci&#243;n del pago. </p>
        <p>9. &#8220;CLEAN &amp; KILL&#8221; podr&#225; negarse a satisfacer la reclamaci&#243;n si &#233;sta es extempor&#225;nea, cuando el producto haya sido usado en condiciones distintas a las recomendadas o propias de su naturaleza o destino o si ha sufrido un deterioro esencial, irreparable y grave por causas imputables a Usted. </p>
        <p>10. &#8220;CLEAN &amp; KILL&#8221; tendr&#225; que autorizar cualquier petici&#243;n o reclamaci&#243;n para iniciar un proceso y validarlo en el mejor tiempo posible, para la satisfacci&#243;n al cliente;  matamoscasinfalible@gmail.com o al tel&#233;fono <b>(33) 15 63 50 78</b>. </p>
        <p>   </p>
        <p><b>XI. Observancia de los T&#233;rminos y Condiciones</b> </p>
        <p>  </p>
        <p>1. Estos &#8220;T&#233;rminos y Condiciones&#8221; se rigen e interpretan de acuerdo con las leyes de M&#233;xico, sin referencia a sus disposiciones en materia de conflictos de ley. La Procuradur&#237;a Federal del Consumidor es competente por la v&#237;a administrativa para resolver las controversias que se susciten sobre el cumplimiento de este contrato </p>
        <p>en los t&#233;rminos y casos previstos por la Ley Federal de Protecci&#243;n al Consumidor. Cualquier y toda controversia sobre la validez o interpretaci&#243;n de estos &#8220;T&#233;rminos y Condiciones&#8221; o su cumplimiento, ser&#225; resuelta exclusivamente por un tribunal competente ubicado en la ciudad de Guadalajara, Jalisco, M&#233;xico, el cual constituir&#225; la &#250;nica sede para cualquier controversia. </p>
        <p>2. &#8220;CLEAN &amp; KILL&#8221; puede terminar este contrato anticipadamente en cualquier momento sin previo aviso y por cualquier motivo, sin responsabilidad y sin necesidad de resoluci&#243;n judicial previa. </p>
        <p> </p>
        <p> </p>

      </Modal.Body>
    </Modal>
  </>
}