import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import imgAmigable from './assets/beneficios/amigable-mascotas-plantas-animales.png';
import imgEnvios from './assets/beneficios/envios-todo-mexico.png';
import imgFormula from './assets/beneficios/formula-mata-insectos.png';
import imgDuracion from './assets/beneficios/larga-duracion.png';
import imgMexicano from './assets/beneficios/producto-100-mexicano.png';

function Beneficios() {

  return (
    <Container id='beneficios' fluid className='mb-4 bg-info'>
      <Row className='align-items-center justify-content-center'>
        <Col xs={12} lg={10}>
          <Row className='my-lg-2 align-items-start justify-content-center py-5' xs={2} md={3} lg={5}>
            <Col className='text-center p-4 p-lg-3'>
              <div className='px-4'>
                <img src={imgDuracion} alt='' className='img-fluid' />
              </div>
              <div className='border border-1 border-white my-3'></div>
              <p className='text-center fs-5 text-white'>
                Larga duración después de aplicarlo y alto rendimiento.
              </p>
            </Col>
            <Col className='text-center p-4 p-lg-3'>
              <div className='px-4'>
                <img src={imgAmigable} alt='' className='img-fluid' />
              </div>
              <div className='border border-1 border-white my-3'></div>
              <p className='text-center fs-5 text-white'>
                Amigable con mascotas, plantas y ambiente.
              </p>
            </Col>
            <Col className='text-center p-4 p-lg-3'>
              <div className='px-4'>
                <img src={imgFormula} alt='' className='img-fluid' />
              </div>
              <div className='border border-1 border-white my-3'></div>
              <p className='text-center fs-5 text-white'>
                Fórmula eficaz que mata insectos al instante.
              </p>
            </Col>
            <Col className='text-center p-4 p-lg-3'>
              <div className='px-4'>
                <img src={imgMexicano} alt='' className='img-fluid' />
              </div>
              <div className='border border-1 border-white my-3'></div>
              <p className='text-center fs-5 text-white'>
                Producto 100% mexicano.
              </p>
            </Col>
            <Col className='text-center p-4 p-lg-3'>
              <div className='px-4'>
                <img src={imgEnvios} alt='' className='img-fluid' />
              </div>
              <div className='border border-1 border-white my-3'></div>
              <p className='text-center fs-5 text-white'>
                Envíos a todo México.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Beneficios;