import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './assets/logo-black.png';
import { IoCart, IoLogoWhatsapp } from "react-icons/io5";

function Header() {

  const tabletBreakpoint = 991;

  const [isTablet, setIsTablet] = useState(window.innerWidth <= tabletBreakpoint);
  const [isTop, setIsTop] = useState(!isTablet);
  const [isInicio, setIsInicio] = useState(isTop);
  const [isComprarAhora, setIsComprarAhora] = useState(false);
  const [isProductos, setIsProductos] = useState(false);
  const [isBeneficios, setIsBeneficios] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setIsTablet(window.innerWidth <= tabletBreakpoint);
      if (isTablet) {
        setIsTop(false);
        setIsInicio(false);
        setIsComprarAhora(false);
        setIsProductos(false);
        setIsBeneficios(false);
      } else {

        setIsInicio(false);
        setIsComprarAhora(false);
        setIsProductos(false);
        setIsBeneficios(false);

        if (window.scrollY !== 0) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }

        if (window.scrollY >= document.getElementById('beneficios').offsetTop - 100) {
          setIsBeneficios(true);
        } else if (window.scrollY >= document.getElementById('productos').offsetTop - 100) {
          setIsProductos(true);
        } else if (window.scrollY >= document.getElementById('comprar-ahora').offsetTop - 100) {
          setIsComprarAhora(true);
        } else {
          setIsInicio(true);
        }
      }
    });
    window.addEventListener('resize', () => {
      setIsTablet(window.innerWidth <= tabletBreakpoint);
    });
  }, [isTop, isTablet]);

  const scrollTo = (id, event) => {
    event.preventDefault();
    window.location.hash = id;
    const top = document.getElementById(id).offsetTop - 90;
    window.scrollTo({
      top: top > 0 ? top : 0,
      behavior: 'smooth'
    });
  }


  return (
    <>
      <Navbar
        id='main-navbar'
        sticky="top"
        expand="lg"
        collapseOnSelect={true}
        className={`bg-white mb-4 ${isTop ? '' : 'shadow-sm'}`}
      >
        <Container>
          <Navbar.Brand href="#inicio" onClick={(e) => scrollTo('inicio', e)}>
            <img src={logo} className="logo" alt="Clean & Kill" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-menu" />
          <Navbar.Collapse id="main-menu">
            <Nav className={isTablet ? 'text-center' : 'ms-auto'}>
              <Nav.Link
                className={isInicio ? 'fw-medium border border-2 rounded-5' : 'fw-light'}
                href="#inicio"
                onClick={(e) => scrollTo('inicio', e)}
              >
                Inicio
              </Nav.Link>
              <Nav.Link
                className={isComprarAhora ? 'fw-medium border border-2 rounded-5' : 'fw-light'}
                href="#comprar-ahora"
                onClick={(e) => scrollTo('comprar-ahora', e)}
              >
                Comprar ahora
              </Nav.Link>
              <Nav.Link
                className={isProductos ? 'fw-medium border border-2 rounded-5' : 'fw-light'}
                href="#productos"
                onClick={(e) => scrollTo('productos', e)}
              >
                Productos
              </Nav.Link>
              <Nav.Link
                className={isBeneficios ? 'fw-medium border border-2 rounded-5' : 'fw-light'}
                href="#beneficios"
                onClick={(e) => scrollTo('beneficios', e)}
              >
                Beneficios
              </Nav.Link>
              <Nav.Link target='_blank' href='https://cleanandkill.myshopify.com/collections/all'>
                {isTablet ? "Tienda" : <IoCart size={25} />}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Button
        variant="link"
        className='whatsapp-float d-flex align-items-center justify-content-center position-fixed rounded-circle bg-white shadow text-primary'
        href='https://wa.me/5213315635078'
        target='_blank'
        rel='noreferrer'
      >
        <IoLogoWhatsapp size={120} />
      </Button>
    </>
  );
}

export default Header;
