
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function PrivacyButton() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return <>
    <Button variant="link" className='text-decoration-none text-white fs-5' onClick={handleShow}>
      Aviso de privacidad
    </Button>

    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title>Aviso de privacidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>¿Quiénes somos?</h4>
        <p>CLEAN & KILL De acuerdo a lo Previsto en la “Ley Federal de Protección de Datos
          Personales”, declara CLEAN & KILL ser una empresa legalmente constituida de
          conformidad con las leyes mexicanas, y como responsable del tratamiento de sus
          datos personales, hace de su conocimiento que la información de nuestros clientes
          es tratada de forma estrictamente confidencial por lo que, al proporcionar sus datos
          personales, tales como:</p>
        <ol>
          <li>Nombre Completo.</li>
          <li>Dirección.</li>
          <li>Registro Federal de Contribuyentes.</li>
          <li>Teléfonos de oficina, celular.</li>
          <li>Correo Electrónico.</li>
        </ol>
        <p>Estos serán utilizados única y exclusivamente para los siguientes fines:</p>
        <ol>
          <li>Envio de la publicidad sobre nuestros servicios</li>
          <li>Información sobre la calidad de nuestros servicios</li>
          <li>Actualización de base de datos</li>
          <li>Otra finalidad análoga a las anteriores.</li>
        </ol>
        <p>
          Todos sus datos personales son tratados de acuerdo a la legislación aplicable y
          vigente en el país, por ello le informamos que usted tiene en todo momento los
          derechos (ARCO) de acceder, rectificar, cancelar u oponerse al tratamiento que le
          damos a sus datos personales; derecho que podrá hacer valer a través del Teléfono
          3315635078 o por medio del correo electrónico: matamoscasinfalible@gmail.com
          cumpliendo con la solicitud correspondiente sujetándose al tiempo establecido.
        </p>
        <p>
          A través de estos canales usted podrá actualizar sus datos y especificar el medio
          por el cual desea recibir información, ya que, en caso de no contar con esta
          especificación de su parte, CLEAN & KILL establecerá libremente el canal que
          considere pertinente para enviarle información.
        </p>
        <p>
          Este aviso de privacidad podrá ser modificado por CLEAN & KILL dichas
          modificaciones serán oportunamente informadas a través de correo electrónico,
          teléfono, o cualquier otro medio de comunicación que CLEAN & KILL determine
          para tal efecto.
        </p>
        <p>
          Si usted considera que su derecho de protección de datos personales ha sido
          lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o
          respuestas, presume que en el tratamiento de sus datos personales existe alguna
          violación a las disposiciones previstas en la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares, podrá interponer la queja o denuncia
          correspondiente ante el IFAI.
        </p>
      </Modal.Body>
    </Modal>
  </>;
}